@import '../../styles/mediamixins.scss';
@import '../../styles/colors.scss';

.congrats-text {
    font-size: 3rem;
}

.congrats-img {
    height: 50vh;
}

.coi-buttons {
    margin-right: 3vw;
}

.no-coi-width {
    width: 250px;
}

.no-coi-paper {
    width: fit-content;
}

.underwriter-bg-img {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position: absolute;
    top: -3px;
    background-image: url('../../assets/remote-img.jpg');
    @include media("<=tablet") {
        background-color: #FAFDFE;
        background-image: url('../../assets/remote-img-mobile.png');
    }
}

.underwriter-main-title {
    font-size: 3.6em;
    margin-left: 70px;
    color: white;
}

.declination-text {
    margin: 29px 110px 0 80px;
}

.user-consent-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.user-consent-img{
    width: 83px;
    height: 98px;
}

.user-failed-consent-img{
    width: 350px;
    height: 95px;
}

@include media("<=tablet") {
    .congrats-text {
        font-size: 2rem;
        max-width: 280px;
        margin-top: -10px !important;
    }   

    .congrats-img-mobile {
        height: 40vh;
        max-width: 91vw;
        object-fit: contain;
    }

    .congrats-page-container {
        background: linear-gradient(180deg, white 0%, #FAFDFE 36%, $iceblue 100%);
        // height: 102vh;
    }

    
}