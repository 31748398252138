@import './colors.scss';
@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.text-small-title {
    @include font-size-map($body-font-size);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $black;
    font-weight: normal;
}


.text-title {
    @include font-size-map($title-font-size);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.text-promotion {
    @include font-size-map($promotion-size);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.text-body {
    @include font-size-map($secondary-font-size);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.text-small {
    @include font-size-map($small-font-size);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.text-tiny {
    @include font-size-map($tiny-font-size);
    font-stretch: normal !important;
    font-style: normal !important;
    letter-spacing: 0.3px !important;
}

.textcenter {
    text-align: center;
}

.textright {
    float: right;
}


.bold {
    font-weight: 600 !important;
}

.clickable {
    cursor: pointer;
}

.underline {
    text-decoration-line: underline !important; 
}

.space-between {
    justify-content: space-between;
}

.account-table-title {
    display: inline-flex;
    align-items: center;
}

.space-evenly { 
    justify-content: space-evenly;
}

hr {
    margin-top: 2vh !important; 
    margin-bottom: 2vh !important;
}

.x-large-icon {
    height: 80px !important;
    width: 80px !important;
}

.large-icon {
    height: 40px;
    width: 40px;
}

.medium-icon {
    height: 28px !important;
    width: 28px !important;
}

.x-medium-icon {
    height: 25px;
    width: 25px;
}

.small-icon {
    height: 20px;
    width: 20px;
}

.tiny-icon {
    width: 18px !important;
    height: 18px !important;
}

.full-width {
    width: 100%;
}

.full-height { 
    height: 100%;
}

.italic {
    font-style: italic;
}

.capitalize {
    text-transform: capitalize;
}

.light { 
    font-weight: 200;
}

.bolder { 
    font-weight: 800 !important;
}

.fit-content {
    width: fit-content
}

.MuiTabs-root {
    overflow: unset !important;
}

.MuiTab-root > .MuiTab-wrapper{
    text-align: left !important;
    align-items: flex-start !important;
}

.MuiGridList-root {
    overflow-y: hidden !important;
}

.text-overflow-elipsis {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-overflow-elipsis-small {
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ai-list {
    height: 400px;
    overflow-y: auto;
}

.small-margin-right {
    margin-right: 15px;
}

.text-overflow-small {
    width: 200px;
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
}

.empty-btn {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}