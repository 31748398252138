@import './colors.scss';

.red {
    color: red
}

.white {
    color: $white
}

.powderblue {
    color: $powderblue;
}

.super-light-blue {
    color: $superLightBlue !important;
}

.backbuttongray {
    color: $backbuttongray;
}

.blue-svg g{
    fill: $blue;
}

.lightgrayblue-stroke-svg g{
    stroke: $lightgrayblue;
}

.powderblue-svg g{
    fill: $powderblue;
}

.tealblue-svg g{
    fill: $powderblue;
}

.powderblue-path-svg path{
    fill: $powderblue;
}

.blue-svg-path path{
    fill: $blue;
}

.white-svg-path path{
    fill: $white;
}

.yellow-svg-path path{
    fill: $yellow;
}

.blue-svg-stroke g{
    stroke: $blue;
}

.bluegrey {
    color: $gray;
    opacity: 0.6;
}

.lightteal {
    color: $lightteal
}

.black {
    color: $black;
}

.blue {
    color: $blue;
}

.skyblue {
    color: $skyblue;
}

.green {
    color: $green;
}

.grey {
    color: $gray;
}

.gray {
    color: $gray !important;
}

.dark-grey {
    color: $dark-grey !important; 
}

.lightgrayblue {
    color: $lightgrayblue !important;
}

.darkbluegray {
    color: $darkbluegray
}

.tealblue {
    color: $tealblue !important;
}

.placeholder-text {
    color: $placeholder-text;
}

.yellow { 
    color: $yellow;
}