html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.MuiContainer-root {
  flex: 1 0 auto !important;
}

@media only screen and (min-width: 768px) {
  body {
    background-image: url('assets/buttom-cover.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    background-attachment: fixed;
  }

  body.nobackground {
    background-image: unset;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    background-attachment: fixed;
  }
}